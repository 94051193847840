import { SMOOTH_SCROLL } from 'constants/index';
import isClient from './isClient';

const DEFAULT_OFFSET = 0;

const scrollToElement = (
  elementId: string,
  offset: number = DEFAULT_OFFSET,
): void => {
  if (!isClient()) {
    return;
  }

  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  window.scrollTo({
    behavior: SMOOTH_SCROLL,
    top: element.offsetTop - offset,
  });
};

export default scrollToElement;
