import 'styles/fonts.css';
import 'styles/reset.css';
import 'styles/global.css';

import React from 'react';
import { RouteUpdateArgs, WrapPageElementBrowserArgs } from 'gatsby';
import bowser from 'bowser';
import hashToElementId from 'utils/hashToElementId';
import scrollToElement from 'utils/scrollToElement';
import { HEADER_HEIGHT } from 'constants/header';
import { RootContext } from 'context/root';

export const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
  const { hash } = location;

  if (hash) {
    const elementId = hashToElementId(hash);

    const device = bowser
      .getParser(window.navigator.userAgent)
      .getPlatformType();

    const offset = HEADER_HEIGHT[device as keyof typeof HEADER_HEIGHT];

    scrollToElement(elementId, offset);
  }
};

export const wrapPageElement = ({
  element,
  props: { location },
}: WrapPageElementBrowserArgs) => (
  <RootContext.Provider value={{ pathname: location.pathname }}>
    {element}
  </RootContext.Provider>
);
